import { Alert, Col, Input, message, Row, Spin, Typography } from 'antd';
import AddressFields from 'components/AddressFields';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import Form from 'components/Form';
import FormItem from 'components/FormItem';
import PhoneNumber from 'components/PhoneNumber';
import PhoneNumberContainer from 'components/PhoneNumberContainer';
import SecureInput from 'components/SecureInput';
import Select, { AsyncSelect } from 'components/Select';
import Space, { Spacer } from 'components/Space/Space';
import UploadFile from 'components/UploadFile/UploadFile';
import { BUSINESS_NAME_REGEX } from 'constants';
import { PROVIDER_APP_PAYMENT_METHODS } from 'containers/Provider';
import { actions, selectors } from 'features/auth';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { providerService } from 'services';
import { PROVIDER_FILE_TYPES } from 'services/providers.service';
import { getNumericValidator, getPhoneEXTValidator, getPhoneNumberValidator, getStringValidator } from 'utils';

const { Title, Paragraph } = Typography;
export default function BusinessProfile() {
  const history = useHistory();
  const [form] = Form.useForm();

  const billingAddressRef = useRef();
  const billingAddressKey = 'address';

  const paymentMethod = Form.useWatch('paymentMethod', form);
  const isDirectDeposit = paymentMethod === PROVIDER_APP_PAYMENT_METHODS.DIRECT_DEPOSIT;

  // Organization data
  const dispatch = useDispatch();
  const organization = useSelector(selectors.selectOrganization);
  const [organizationUpdating, setOrganizationUpdating] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      businessLegalName: organization?.businessLegalName,
      businessPhone: organization?.businessPhone,
      businessPhoneExt: organization?.businessPhoneExt,
      businessContact: {
        firstName: organization?.businessContact?.firstName,
        lastName: organization?.businessContact?.lastName,
        position: organization?.businessContact?.position,
        email: organization?.businessContact?.email,
        phone: organization?.businessContact?.phone,
        phoneExt: organization?.businessContact?.phoneExt,
      },
      taxClassification: organization?.taxClassification,
      taxIdNumber: organization?.taxIdNumber,
      paymentMethod: organization?.banking?.directDeposit
        ? PROVIDER_APP_PAYMENT_METHODS.DIRECT_DEPOSIT
        : PROVIDER_APP_PAYMENT_METHODS.PAPER_CHECK,
      banking: organization?.banking,
      address: organization?.address,
    });
  }, [form, organization]);

  const [orgFiles, setOrgFiles] = useState(null);
  // Fetch organization files
  useAsync(async () => {
    if (organization?.id !== undefined) {
      try {
        const _orgFiles = await providerService.getOrgFiles(organization?.id);
        setOrgFiles(_orgFiles);
      } catch (error) {
        console.error('Error fetching organization files', error);
      }
    }
  }, [organization]);

  const w9Files = useMemo(() => orgFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.W9.code), [orgFiles]);

  const isDisabled = useMemo(() => !!organization && !!organization?.profileComplete, [organization]);

  return (
    <Spin spinning={organization === null}>
      {isDisabled && (
        <Alert
          message={
            <>
              Your changes have been saved. Contact Provider Services to make changes:{' '}
              <a
                href="mailto:providerservices@usa.childcareaware.org"
                className="text-[rgb(0,0,0)] hover:text-[rgba(0,0,0,0.75)] underline hover:underline"
              >
                providerservices@usa.childcareaware.org
              </a>
            </>
          }
          className="mb-8"
        />
      )}

      {!isDisabled && (
        <Alert
          message={<span className="text-2xl font-bold">Welcome!</span>}
          description="Finish your business profile to continue applying for fee assistance."
          type="info"
          className="mb-8"
        />
      )}

      <Title>Business profile</Title>

      <Form
        onFinish={async (values) => {
          try {
            setOrganizationUpdating(true);

            // set the directDeposit value
            values.banking = {
              ...values.banking,
              directDeposit: paymentMethod === PROVIDER_APP_PAYMENT_METHODS.DIRECT_DEPOSIT,
            };

            // call the api to save the data
            const _updatedOrganization = await providerService.updateOrganization(organization?.id, values);

            // update the organization in the store
            dispatch(actions.setOrganization(_updatedOrganization));

            // show the success message
            message.success('Business profile saved');

            // redirect to the next page
            history.push('/provider/locations-and-programs');
          } catch (error) {
            // show the error message
            message.error('Unable to save the business profile');
          } finally {
            setOrganizationUpdating(false);
          }
        }}
        name="business-profile"
        form={form}
        layout="vertical"
        className="mt-4"
        disabled={isDisabled}
      >
        <Card
          title={
            <>
              <Title level={2} className="mt-4">
                Business contact
              </Title>
              <Paragraph style={{ fontWeight: 'normal' }}>
                The business contact is the person who will manage payments and remittance.
              </Paragraph>
            </>
          }
          className="mb-8"
          data-testid="businessContactCard"
        >
          <Row gutter={[20, 0]} className="form-row">
            <Col xs={24} sm={22} md={12}>
              <FormItem
                name={['businessContact', 'firstName']}
                label="FIRST NAME"
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <Input aria-label="First name" data-testid="firstName" />
              </FormItem>
            </Col>

            <Col xs={24} sm={22} md={12}>
              <FormItem
                name={['businessContact', 'lastName']}
                label="LAST NAME"
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <Input aria-label="Last name" data-testid="lastName" />
              </FormItem>
            </Col>
          </Row>

          <Col xs={18} sm={15} md={15}>
            <FormItem
              name={['businessContact', 'position']}
              label="ROLE/POSITION"
              rules={[{ required: true, message: 'Role/position is required' }]}
            >
              <Input aria-label="position" data-testid="position" />
            </FormItem>

            <FormItem
              name={['businessContact', 'email']}
              label="Email"
              rules={[{ required: true, message: 'Email is required' }]}
            >
              <Input aria-label="business email" data-testid="businessEmail" />
            </FormItem>
          </Col>

          <Col xs={18} sm={15} md={12}>
            <PhoneNumberContainer
              phoneNumber={
                <FormItem
                  name={['businessContact', 'phone']}
                  label="Phone number"
                  rules={[
                    { required: true, message: 'Phone number is required' },
                    getPhoneNumberValidator('Invalid Phone Number'),
                  ]}
                  className="mb-0"
                >
                  <PhoneNumber aria-label="phone number" disabled={isDisabled} data-testid="businessContact" />
                </FormItem>
              }
              extension={
                <FormItem
                  name={['businessContact', 'phoneExt']}
                  label="Extension"
                  rules={[getPhoneEXTValidator('Invalid Extension')]}
                  className="mb-0"
                >
                  <Input aria-label="phone extension" data-testid="businessContactExt" />
                </FormItem>
              }
            />
          </Col>
        </Card>

        <Card
          title={
            <>
              <Title level={2} className="mt-4">
                Business address and phone
              </Title>

              <Paragraph style={{ fontWeight: 'normal' }}>
                Add your business mailing address. This information must match your IRS Form W-9.
              </Paragraph>
            </>
          }
          className="mb-8"
          data-testid="businessAddressAndPhoneCard"
        >
          <AddressFields
            id="billing-address"
            form={form}
            searchRef={billingAddressRef}
            addressKey={billingAddressKey}
            readOnly={isDisabled}
          />

          <Col xs={18} sm={15} md={12}>
            <PhoneNumberContainer
              phoneNumber={
                <FormItem
                  name={['businessPhone']}
                  label="Phone number"
                  rules={[
                    { required: true, message: 'Phone number is required' },
                    getPhoneNumberValidator('Invalid Phone Number'),
                  ]}
                  className="mb-0"
                >
                  <PhoneNumber aria-label="phone number" disabled={isDisabled} data-testid="businessPhoneNumber" />
                </FormItem>
              }
              extension={
                <FormItem
                  name={['businessPhoneExt']}
                  label="Extension"
                  rules={[getPhoneEXTValidator('Invalid Extension.')]}
                  className="mb-0"
                >
                  <Input aria-label="phone extension" data-testid="businessPhoneNumberExt" />
                </FormItem>
              }
            />
          </Col>
        </Card>

        <Card
          title={
            <>
              <Title level={2} className="mt-4">
                Tax details
              </Title>

              <Paragraph style={{ fontWeight: 'normal' }}>
                Add your business details. This information must match your IRS Form W-9.
              </Paragraph>
            </>
          }
          className="mb-8"
          data-testid="taxDetailsCard"
        >
          <Col xs={24} sm={12} md={18}>
            <FormItem
              name="businessLegalName"
              label="BUSINESS LEGAL NAME"
              rules={[
                { required: true, message: 'Business legal name is required' },
                { type: 'string', message: 'Invalid business legal name' },
                { max: 256, message: 'Business legal name is too long' },
                { min: 3, message: 'Business legal name is too short' },
                {
                  pattern: BUSINESS_NAME_REGEX,
                  message: 'Business legal name is invalid',
                },
                {
                  validator: getStringValidator('Business legal name is invalid'),
                },
              ]}
              validateFirst
            >
              <Input data-testid="businessLegalName" aria-label="business legal name" />
            </FormItem>
          </Col>

          <Col xs={24} sm={12} md={10}>
            <FormItem
              name="taxClassification"
              label="TAX CLASSIFICATION"
              rules={[{ required: true, message: 'Tax classification is required' }]}
              isSame={(prev, curr) => prev?.id === curr?.id}
            >
              <AsyncSelect
                name="taxClassification"
                optionsApiUrl="/options/21"
                aria-label="Tax Classification"
                disabled={isDisabled}
              />
            </FormItem>
          </Col>

          <Col xs={24} sm={12} md={10}>
            <FormItem
              name="taxIdNumber"
              label="TAX ID"
              rules={[
                { required: true, message: 'Tax ID number is required' },
                { pattern: /^\d{9}$/g, message: 'Tax ID number must have 9 digits' },
              ]}
            >
              <SecureInput size="middle" aria-label="Tax ID Number" data-testid="taxID" />
            </FormItem>
          </Col>

          <FormItem
            name="w9"
            label={
              <>
                <span className="text-red-500 mr-1 font-normal">*</span>
                W-9
              </>
            }
            rules={[
              () => ({
                validator() {
                  if (w9Files.length === 0) {
                    return Promise.reject(new Error('W-9 is required'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            className="max-w-96"
          >
            <UploadFile
              fileCode={PROVIDER_FILE_TYPES.W9.code}
              orgId={organization?.id}
              files={orgFiles}
              setFiles={setOrgFiles}
              readOnly={isDisabled}
            />
          </FormItem>

          <FormItem name="ein" label="EIN Certificate" className="mb-0 max-w-96">
            <UploadFile
              fileCode={PROVIDER_FILE_TYPES.EIN.code}
              orgId={organization?.id}
              files={orgFiles}
              setFiles={setOrgFiles}
              readOnly={isDisabled}
            />
          </FormItem>
        </Card>

        <Card
          title={
            <>
              <Title level={2} className="mt-4">
                Payment details
              </Title>

              <Paragraph style={{ fontWeight: 'normal' }}>
                Select how you will receive reimbursement payments. Direct deposit ensures the most timely receipt of
                reimbursement payments.
              </Paragraph>
            </>
          }
          className="mb-8"
          data-testid="paymentDetailsCard"
        >
          <Col xs={13} sm={12} md={9}>
            <FormItem
              name="paymentMethod"
              label="PAYMENT METHOD"
              rules={[{ required: true, message: 'Payment method is required' }]}
            >
              <Select
                options={[
                  { label: 'Paper check', value: PROVIDER_APP_PAYMENT_METHODS.PAPER_CHECK },
                  { label: 'Direct deposit', value: PROVIDER_APP_PAYMENT_METHODS.DIRECT_DEPOSIT },
                ]}
                isOptionSelected={(option, [value]) => option?.value === value}
                getOptionLabel={(opt) => opt.label}
                onChangeFormatter={(v) => v?.value}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 32, // Set the height you want here
                    minHeight: 32,
                    borderRadius: '2px',
                  }),
                }}
                aria-label="payment method dropdown"
                data-testid="paymentMethod"
                disabled={isDisabled}
              />
            </FormItem>

            {/* keeping the items in DOM for now, seems like their values get cleared somehow when they are not in the DOM */}
            <div className={!isDirectDeposit ? 'hidden' : ''}>
              <FormItem
                name={['banking', 'accountType']}
                label="ACCOUNT TYPE"
                rules={!isDirectDeposit ? undefined : [{ required: true, message: 'Account type is required' }]}
                isSame={(prev, curr) => prev?.id === curr?.id}
              >
                <AsyncSelect
                  name="banking_accountType"
                  optionsApiUrl="/options/7"
                  aria-label="Account type"
                  disabled={isDisabled}
                />
              </FormItem>

              <FormItem
                name={['banking', 'bankName']}
                label="Bank Name"
                rules={!isDirectDeposit ? undefined : [{ required: true, message: 'Bank name is required.' }]}
              >
                <Input aria-label="bank name" data-testid="bankName" />
              </FormItem>

              <FormItem
                name={['banking', 'routingNumber']}
                label="Routing Number"
                rules={
                  !isDirectDeposit
                    ? undefined
                    : [
                        { required: true, message: 'Routing number is required.' },
                        { validator: getNumericValidator('Invalid Routing Number.') },
                      ]
                }
              >
                <SecureInput aria-label="routing number" />
              </FormItem>

              <FormItem
                name={['banking', 'accountNumber']}
                label="Account Number"
                rules={
                  !isDirectDeposit
                    ? undefined
                    : [
                        { required: true, message: 'Account number is required.' },
                        { validator: getNumericValidator('Invalid Account Number.') },
                      ]
                }
              >
                <SecureInput aria-label="account number" />
              </FormItem>
            </div>
          </Col>

          {paymentMethod !== null && (
            <>
              {paymentMethod !== PROVIDER_APP_PAYMENT_METHODS.PAPER_CHECK && (
                <p className="instructions mb-5">Your first payment will always be made via paper check.</p>
              )}

              <Col xs={13} sm={12} md={9}>
                <FormItem
                  name={['banking', 'paymentName']}
                  rules={[
                    {
                      required: true,
                      message: 'Payment name is required',
                    },
                  ]}
                  label="MAKE CHECK PAYABLE TO"
                >
                  <Input aria-label="make check payable to" data-testid="payableTo" />
                </FormItem>
              </Col>
            </>
          )}

          <FormItem
            name="direct_deposit_or_voided_check"
            label="Upload a copy of a voided check or a letter from your bank that verifies your account details"
            className="mb-0 max-w-96"
          >
            <UploadFile
              fileCode={PROVIDER_FILE_TYPES.DIRECT_DEPOSIT.code}
              orgId={organization?.id}
              files={orgFiles}
              setFiles={setOrgFiles}
              readOnly={isDisabled}
            />
          </FormItem>
        </Card>

        {!isDisabled && (
          <Card
            title={
              <Title level={2} className="mt-4">
                Ready to save?
              </Title>
            }
            className="mb-4"
            data-testid="readyToSaveCard"
          >
            <p>
              Review your answers carefully before saving. After you click “Save profile”, you will need to email
              Provider Services to make changes.
            </p>

            <Space className="mt-4">
              <Spacer />

              <Button type="primary" htmlType="submit" loading={organizationUpdating}>
                Save profile
              </Button>
            </Space>
          </Card>
        )}
      </Form>
    </Spin>
  );
}
