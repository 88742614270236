import React, { useState } from 'react';
import { Button, message, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectOrganization } from 'features/auth';
import Card from 'components/Card/Card';
import { Add } from '@carbon/icons-react';
import PageMeta from 'components/PageMeta/PageMeta';
import { providerService } from 'services';
import { useAsync } from 'react-async-hook';
import { PROVIDER_APPLICATION_PROGRAM_TYPES } from '../SelectProgram/SelectProgram';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const { Paragraph, Title } = Typography;

const APPLICATION_STATUS_MAPPING = {
  INCOMPLETE: 'In progress',
  PENDING: 'Submitted',
};

export default function Applications() {
  const history = useHistory();
  const organization = useSelector(selectOrganization);

  const [careFacilities, setCareFacilities] = useState(null);

  useAsync(async () => {
    try {
      const _careFacilities = await providerService.getCareFacilities(organization.id);

      // fetch all the care facility applications
      const _applications = await Promise.all(
        _careFacilities?.content.map((careFacility) => providerService.getCarefacilityApplications(careFacility.id)),
      );

      // attach applications to care facilities
      _careFacilities?.content.forEach((careFacility, index) => {
        careFacility.applications = _applications[index];
      });

      setCareFacilities(_careFacilities?.content);
    } catch (error) {
      console.log('Error fetching care facilities', error);
      message.error('Failed to fetch care facilities');
    }
  }, [organization.id]);

  return (
    <div className="layout-page">
      <PageMeta title="My Applications" />

      <div id="page-top-placeholder" />

      <Spin spinning={!careFacilities}>
        {Array.isArray(careFacilities) && (
          <Card
            noBodyPadding
            title={
              <>
                <Title level={2} role="heading" aria-level={1} className="!mb-0 mt-6">
                  Locations and programs
                </Title>

                <Paragraph className="block !mb-8">
                  Manage your child care locations and program participation for fee assistance programs.
                </Paragraph>
              </>
            }
            extra={
              <div className="flex space-x-3">
                <Button
                  icon={<Add size={20} className="ml-[-8px] mr-[-2px]" />}
                  onClick={() => history.push('/provider/facility')}
                >
                  Add location
                </Button>
              </div>
            }
          >
            <div className="flex-col divide-y-[1px] divide-solid divide-[#c8c8c8] border-t border-solid border-t-[#c8c8c8]">
              {careFacilities?.length !== 0 &&
                careFacilities.map((careFacility, index) => {
                  return (
                    <div
                      key={careFacility.id}
                      className="p-6 flex"
                      style={{
                        backgroundColor: index % 2 === 0 ? '#FAFAFA' : undefined,
                      }}
                    >
                      <div className="flex-grow w-1/2">
                        <Title level={4} role="heading" aria-level={2}>
                          {careFacility.displayName || '—'}
                        </Title>
                        <Paragraph>
                          {careFacility.address ? (
                            <>
                              <div className="flex-col">
                                <p className="!mb-0">{careFacility.address.street}</p>

                                {careFacility.address.street2 && (
                                  <p className="!mb-0">{careFacility.address.street2}</p>
                                )}

                                <p className="!mb-0">
                                  {careFacility.address.city}, {careFacility.address.state}{' '}
                                  {careFacility.address.postalCode}
                                </p>
                              </div>
                            </>
                          ) : (
                            'N/A'
                          )}
                        </Paragraph>

                        {careFacility.profileComplete ? (
                          <>
                            <Link to={`/provider/facility/${careFacility.id}`} className="table mt-7">
                              <Button>View location</Button>
                            </Link>

                            {Array.isArray(careFacility.applications) && careFacility.applications.length > 0 && (
                              <Link
                                to={`/provider/${careFacility.id}/add-application`}
                                className="table mt-7 underline"
                              >
                                Apply for another program
                              </Link>
                            )}
                          </>
                        ) : (
                          <Link to={`/provider/facility/${careFacility.id}`} className="table mt-7">
                            <Button type="primary">Finish adding location</Button>
                          </Link>
                        )}
                      </div>

                      {careFacility.profileComplete && (
                        <>
                          {Array.isArray(careFacility.applications) && careFacility.applications.length > 0 ? (
                            <div className="flex-grow w-1/2">
                              <div className="flex flex-col divide-y divide-solid divide-[#c8c8c8]">
                                {careFacility?.applications?.map((application) => {
                                  const _programTypeName = Object.values(PROVIDER_APPLICATION_PROGRAM_TYPES).find(
                                    (programType) => programType.id === application.programType.id,
                                  )?.name;

                                  return (
                                    <div key={application.id} className="flex justify-between items-center">
                                      <div className="pt-4 pb-1">
                                        <Title
                                          level={5}
                                          role="heading"
                                          aria-level={3}
                                          className="font-bold"
                                          style={{
                                            color: 'rgba(0, 0, 0, 0.85)',
                                          }}
                                        >
                                          {_programTypeName || '—'}
                                        </Title>
                                        <Paragraph className="text-[#000] font-semibold">
                                          {APPLICATION_STATUS_MAPPING[application.status?.status?.title] ||
                                            application.status?.status?.description ||
                                            '—'}
                                        </Paragraph>
                                      </div>

                                      <div>
                                        <Link
                                          to={`/provider/applications/${application.id}`}
                                          className="underline"
                                          data-testid="application-cta"
                                        >
                                          {application?.status?.status?.title === 'INCOMPLETE' ? (
                                            <Button type="primary">Resume application</Button>
                                          ) : (
                                            'View application'
                                          )}
                                        </Link>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <Link to={`/provider/${careFacility.id}/add-application`} className="table mt-7 underline">
                              <Button type="primary">Apply for fee assistance</Button>
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </Card>
        )}
      </Spin>
    </div>
  );
}
