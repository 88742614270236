import { CloudUpload } from '@carbon/icons-react';
import { Button, message, Upload } from 'antd';
import { useState } from 'react';
import { providerService } from 'services';

export default function UploadFile({ fileCode, files, setFiles, orgId, applicationId, careFacilityId, readOnly }) {
  const [fileUploading, setFileUploading] = useState(false);

  return (
    <Upload
      {...{
        accept: '.pdf, .jpg, .jpeg, .png',
        beforeUpload: (file) => {
          if (checkIfFileTypeIsSupported(file) === false) {
            handleUnsupportedFileType(file);
            return false || Upload.LIST_IGNORE;
          }

          if (checkIfFileSizeIsSupported(file) === false) {
            handleUnsupportedFileSize(file);
            return false || Upload.LIST_IGNORE;
          }
        },
        customRequest: async ({ file, onSuccess, onError }) => {
          try {
            setFileUploading(true);

            let _uploadedFile;
            if (orgId) {
              _uploadedFile = await providerService.uploadOrgFile(orgId, file, fileCode);
            } else if (applicationId) {
              _uploadedFile = await providerService.uploadApplicationFile(applicationId, file, fileCode);
            } else if (careFacilityId) {
              _uploadedFile = await providerService.uploadCareFacilityFile(careFacilityId, file, fileCode);
            }

            onSuccess(_uploadedFile);
            message.success('File uploaded');

            setFiles([...files, _uploadedFile]);
          } catch (error) {
            onError(error);
            console.log('Error uploading file', error);
            message.error('Something went wrong');
          } finally {
            setFileUploading(false);
          }
        },
        fileList: files
          ?.filter((file) => file.fileType === fileCode)
          ?.map((file) => ({
            uid: file.id,
            name: file.name,
            status: 'done',
          })),
      }}
      onRemove={async (file) => {
        if (orgId) {
          await providerService.deleteOrgFile(orgId, file.uid);
        } else if (applicationId) {
          await providerService.deleteApplicationFile(applicationId, file.uid);
        } else if (careFacilityId) {
          await providerService.deleteCareFacilityFile(careFacilityId, file.uid);
        }

        setFiles(files?.filter((f) => f.id !== file.uid));

        message.success('File removed');
      }}
      showUploadList={{
        showRemoveIcon: !readOnly,
        removeIcon: <a className="px-4 underline hover:underline">Remove</a>,
      }}
      data-testid="uploadFile"
    >
      {!readOnly && (
        <Button icon={<CloudUpload />} loading={fileUploading} disabled={fileUploading}>
          {fileUploading ? 'Uploading...' : 'Upload file'}
        </Button>
      )}
    </Upload>
  );
}

export const checkIfFileTypeIsSupported = (file) => {
  const isAcceptedImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type);
  const isPDF = ['application/pdf', 'application/octet-stream'].includes(file.type);

  return isAcceptedImage || isPDF;
};

export const handleUnsupportedFileType = (file) => {
  message.error(`${file.name} must be a jpg, png, or pdf file`);
};

export const checkIfFileSizeIsSupported = (file) => {
  const FILESIZE_LIMIT = 10 * 1024 * 1024; // 10MB
  return file.size <= FILESIZE_LIMIT;
};

export const handleUnsupportedFileSize = (file) => {
  message.error(`${file.name} must be smaller than 10MB`);
};
